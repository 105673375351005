/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"

import "./index.css"

type LayoutType = {
  children: any
  className?: string
}
const Layout: React.FC<LayoutType> = ({ children, className }:any) => {
  return <main className={`layoutCustomCss ${className}`}>{children}</main>
}

export default Layout
